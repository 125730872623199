import * as React from "react"
import { keyframes } from "@emotion/react"
import { Box, BoxOwnProps, Flex, Text } from "theme-ui"

import { Icon } from "./Icon"

export interface UiButtonOwnProps extends BoxOwnProps {
  variant?: "primary" | "secondary" | "ghost" | "link" | "unstyled"
  isVisuallyDisabled?: boolean
  isLoading?: boolean
  isSuccessful?: boolean
  subLabel?: React.ReactNode
  hoverLabel?: React.ReactNode
  iconRight?: React.ReactNode
  iconLeft?: React.ReactNode
}

export type UiButtonProps<As extends React.ElementType> = UiButtonOwnProps &
  Omit<React.ComponentPropsWithRef<As>, "as" | keyof UiButtonOwnProps> & {
    as?: As
  }

const fadeIn = keyframes({
  from: { opacity: 0.2 },
  to: { opacity: 1 },
})

export const UiButton = <As extends React.ElementType>({
  children,
  sx,
  subLabel,
  hoverLabel,
  isVisuallyDisabled,
  isLoading,
  isSuccessful,
  variant = "primary",
  iconRight,
  iconLeft,
  ...rest
}: UiButtonProps<As>) => (
  <Box
    {...rest}
    className={`ui-button ${rest.className || ""}`}
    sx={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: variant !== "link" && variant !== "unstyled" ? 11 : null,
      position: "relative",
      fontSize: "sm",
      color:
        variant === "primary"
          ? "grayscale.white"
          : (variant === "secondary" && isVisuallyDisabled) ||
            (variant === "ghost" && isVisuallyDisabled)
          ? "grayscale.600"
          : "primary",
      backgroundColor:
        variant === "primary" && isVisuallyDisabled
          ? "grayscale.600"
          : variant === "primary"
          ? "grayscale.700"
          : variant === "secondary"
          ? "grayscale.100"
          : variant === "ghost" || variant === "link" || variant === "unstyled"
          ? "transparent"
          : null,
      border: variant === "ghost" ? "1px solid" : "none",
      borderColor:
        variant === "ghost" && isVisuallyDisabled
          ? "grayscale.400"
          : "grayscale.600",
      textDecoration: "none",
      appearance: "none",
      cursor: isVisuallyDisabled ? "default" : "pointer",
      transition: "color .2s, background-color .2s, borderColor .2s",
      paddingInline: variant !== "link" && variant !== "unstyled" ? 4 : 0,
      paddingBlock: 0,
      ":hover": {
        color:
          (variant === "secondary" || variant === "ghost") &&
          !isVisuallyDisabled
            ? "primary"
            : null,
        backgroundColor:
          variant === "primary" && !isVisuallyDisabled
            ? "primary"
            : variant === "secondary" && !isVisuallyDisabled
            ? "grayscale.200"
            : null,
        borderColor: isVisuallyDisabled ? null : "primary",
        ".label": {
          opacity: 0,
          visibility: "hidden",
        },
        ".label-hover, &[data-has-hover-label-mobile='false'] .label": {
          opacity: 1,
          visibility: "visible",
        },
      },
      ".label, .label-hover": {
        transition: "opacity .2s, visibility .2s",
      },
      "&[data-has-hover-label-mobile='true'] .label, .label-hover, &[data-has-hover-label-mobile='false'] .label-hover":
        {
          opacity: 0,
          visibility: "hidden",
        },
      "&[data-has-hover-label-mobile='true'] .label-hover": {
        opacity: 1,
        visibility: "visible",
      },
      ...sx,
    }}
    onTouchStart={(e) => {
      const el = (e.target as HTMLElement).closest(".ui-button") as HTMLElement

      el.dataset.hasHoverLabelMobile = "true"
      setTimeout(() => {
        el.dataset.hasHoverLabelMobile = "false"
      }, 2000)
    }}
  >
    {isLoading ? (
      <Flex
        sx={{
          justifyContent: "center",
          div: {
            width: 1,
            height: 1,
            borderRadius: "100%",
            backgroundColor: "currentColor",
            marginInline: 1,
            opacity: 0.2,
          },
        }}
      >
        <Box sx={{ animation: `${fadeIn} .6s linear infinite alternate` }} />
        <Box
          sx={{ animation: `${fadeIn} .6s linear .15s infinite alternate` }}
        />
        <Box
          sx={{ animation: `${fadeIn} .6s linear .30s infinite alternate` }}
        />
      </Flex>
    ) : isSuccessful ? (
      <Icon name="checkmark-thin" />
    ) : subLabel ? (
      <Flex
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text>{children}</Text>
        <Text sx={{ marginInlineStart: 4 }}>{subLabel}</Text>
      </Flex>
    ) : iconRight ? (
      <Flex sx={{ alignItems: "center" }}>
        {children && (
          <Text className={hoverLabel ? "label" : ""}>
            <Text sx={{ paddingInlineEnd: 2 }}>{children}</Text>
          </Text>
        )}
        {iconRight}
      </Flex>
    ) : iconLeft ? (
      <Flex sx={{ alignItems: "center" }}>
        {iconLeft}
        {children && (
          <Text className={hoverLabel ? "label" : ""}>
            <Text sx={{ paddingInlineStart: 2 }}>{children}</Text>
          </Text>
        )}
      </Flex>
    ) : (
      <>
        {hoverLabel && (
          <Text
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
            }}
            className="label-hover"
          >
            {hoverLabel}
          </Text>
        )}
        <Text className={hoverLabel ? "label" : ""}>{children}</Text>
      </>
    )}
  </Box>
)
